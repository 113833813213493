import { SortValue } from 'hooks/useSearchParamManager'
import SortArrow from 'components/sort-arrow'
import StyledSortableHeader from 'components/list/sortable-header'

interface SortableColumnProps<T> {
  columnTitle: string
  columnSortValues: SortValue<T>
  sortedValues: SortValue<T>
  setSort: (sort: SortValue<T>) => void
}

/**
 * SortableColumn component
 *
 * This component renders a sortable column header. When clicked, it updates the sort order
 * based on the current sort state. It displays a sort arrow indicating the current sort direction.
 *
 * @param {string} props.columnTitle - The title of the column to be displayed.
 * @param {SortValue} props.columnSortValues - The sort values associated with this column.
 * @param {SortValue} props.sortedValues - The current sorted values.
 * @param {Function} props.setSort - Function to update the sort values.
 *
 * @returns {JSX.Element} - A sortable column header component.
 */
export const SortableColumn = <T,>({
  columnTitle,
  columnSortValues,
  sortedValues,
  setSort,
}: SortableColumnProps<T>): JSX.Element => {
  const isSorted = columnSortValues.sortColumn === sortedValues.sortColumn
  const nextSortOrder =
    isSorted && sortedValues.sortOrder === 'asc' ? 'desc' : 'asc'

  return (
    <StyledSortableHeader
      onClick={() => setSort({ ...columnSortValues, sortOrder: nextSortOrder })}
    >
      {columnTitle}
      <SortArrow sorted={isSorted} asc={nextSortOrder === 'asc'} />
    </StyledSortableHeader>
  )
}
