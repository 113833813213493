import styled, { css } from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'

interface GridRowProps {
  gridTemplateColumns: string
  header?: boolean
  cursor?: 'pointer' | 'auto'
}

const StyledGridRow = styled.ul<GridRowProps>`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  margin-bottom: 0;
  margin-top: -1px;
  border-width: 1px 0;
  border-color: ${COLOR.GREY.LIGHT};
  border-style: solid;
  padding: ${GTR.S} ${GTR.XS};
  cursor: ${(props) => props.cursor || 'pointer'};
  align-items: center;

  li {
    padding-right: ${GTR.S};
  }

  ${({ header }) =>
    header
      ? css`
          border-width: 0 0 2px;
          cursor: initial;
        `
      : css`
          &:hover > li:last-of-type {
            transform: translate(-${GTR.XXS});
            transition: transform 0.15s ease-in-out;
          }
        `}
`

export default StyledGridRow
