import { useState, useEffect } from 'react'
import { strToBool, isStrBoolProp } from 'utils/helpers'

const useLocalStorage = <T>(
  key: string,
  initialValue?: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(key)
    let parsedValue

    if (storedValue) {
      parsedValue = JSON.parse(storedValue)
    } else if (typeof initialValue !== 'undefined') {
      parsedValue = initialValue
    } else {
      parsedValue = ''
    }

    return isStrBoolProp(parsedValue) ? strToBool(parsedValue) : parsedValue
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

export default useLocalStorage
