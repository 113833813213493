import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose,
} from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import reducer from './reducers'

import * as Sentry from '@sentry/react'

let composeEnhancers = compose
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

// See: https://docs.sentry.io/platforms/javascript/guides/react/features/redux/#redux-enhancer-options
const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const createStore = (initialState?: undefined) =>
  reduxCreateStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(promise, thunk), sentryReduxEnhancer)
  )

const store = createStore()
export default store

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
