import styled from 'styled-components'
import { COLOR, FONT } from '@farewill/ui/tokens'

type BadgeSate = 'success' | 'info' | 'error' | 'primary'
const Badge = styled.span<{
  $state?: BadgeSate
}>`
  padding: 4px 10px;
  color: ${({ $state }) => getColorFromState($state)};
  background: ${({ $state }) => getBgColorFromState($state)};
  border-radius: 13px;
  font-size: ${FONT.SIZE.XXS};
  font-weight: ${FONT.WEIGHT.BOLD};
  text-transform: uppercase;
  font-family: ${FONT.FAMILY.BODY};
`

export default Badge

function getBgColorFromState(state?: BadgeSate) {
  switch (state) {
    case 'error':
      return COLOR.STATE.ERROR_10
    case 'primary':
      return COLOR.ACCENT.PRIMARY_10
    case 'info':
      return COLOR.ACCENT.SECONDARY_10
    case 'success':
    default:
      return COLOR.STATE.SUCCESS_10
  }
}

function getColorFromState(state?: BadgeSate) {
  switch (state) {
    case 'error':
      return COLOR.STATE.ERROR
    case 'primary':
    case 'info':
      return COLOR.GREY.DARK
    case 'success':
    default:
      return COLOR.STATE.SUCCESS
  }
}
