import { useState, useEffect, useCallback } from 'react'

import useLocalStorage from 'hooks/useLocalStorage'
import { strToBool } from 'utils/helpers'
import { useHistory } from 'react-router-dom'
import { isPlainObject, omit } from 'lodash'
import { IndexableKey } from 'utils/types/indexable-key'

export type RangeFilter = { lte: string; gte: string; option: string }
export type ArrayFilter = string[]

type FilterValue = string | ArrayFilter | boolean | RangeFilter

export type FilterValues<T> = Record<IndexableKey<T>, FilterValue>

export interface SortValue<T> {
  sortColumn?: IndexableKey<T>
  sortOrder?: 'asc' | 'desc'
  reverseNulls?: boolean
}

export interface PaginateValue {
  before?: string
  after?: string
  size?: number
}

export type PageValues<T> = SortValue<T> & PaginateValue

interface StorageConfig {
  storageEnabled: boolean
  storageKey: string
}

/**
 * Custom hook to manage search parameters in the URL and local storage.
 *
 * This hook synchronizes filter and pagination values with the URL search parameters
 * and optionally with local storage. It provides functions to get and set these values,
 * ensuring that search state is reflected in the URL and can be persisted across sessions.
 *
 * @param {FilterValues} initialFilterValues - Initial filter values to be used if none are found in the URL or local storage.
 * @param {PageValues} initialPageVaues - Initial pagination values to be used if none are found in the URL or local storage.
 * @param {StorageConfig} storageConfig - Configuration for local storage, including whether it is enabled and the storage key to use.
 * @returns {Object} - An object containing the current filters, pagination values, and functions to get and set these values.
 */
const useSearchParamManager = <F, P>(
  initialFilterValues: FilterValues<F>,
  initialPageVaues: PageValues<P>,
  { storageEnabled, storageKey }: StorageConfig
) => {
  const history = useHistory()
  const [apiSearchParams, setApiSearchParams] = useState<string>('')
  const [filters, setFilters] = useState<FilterValues<F> | null>(null)
  const [page, setPage] = useState<PageValues<P> | null>(null)
  const [savedFilters, setSavedFilters] = useLocalStorage<string>(storageKey)

  const getSearchHistory = useCallback(() => {
    const urlParams =
      storageEnabled && savedFilters
        ? new URLSearchParams(savedFilters)
        : new URLSearchParams(history.location.search)

    const filters = parseUrlValues(urlParams, 'filter', initialFilterValues)
    const page = parseUrlValues(urlParams, 'page', initialPageVaues)
    setFilters(filters)
    setPage(page)
  }, [
    history.location.search,
    savedFilters,
    initialFilterValues,
    initialPageVaues,
    storageEnabled,
  ])

  const setSearchHistory = useCallback(() => {
    const apiSearchParams = new URLSearchParams()
    const urlSearchParams = new URLSearchParams()

    if (filters !== null) {
      Object.keys(filters).forEach((filterKey) => {
        const value = filters[filterKey as IndexableKey<F>]
        const filterHasValue = Array.isArray(value) ? value.length : !!value

        if (filterHasValue && !isPlainObject(value)) {
          const filterParam = urlSearchParamKey('filter', filterKey)
          urlSearchParams.set(filterParam, value.toString())
          apiSearchParams.set(filterParam, value.toString())
        } else if (isRangeFilter(value)) {
          const { gte, lte, option } = value
          if (gte && lte) {
            urlSearchParams.set(
              urlSearchParamKey('filter', filterKey, 'option'),
              option
            )
            apiSearchParams.set(
              urlSearchParamKey('filter', filterKey, 'gte'),
              gte
            )
            apiSearchParams.set(
              urlSearchParamKey('filter', filterKey, 'lte'),
              lte
            )
          }
        }
      })
    }

    if (page !== null) {
      Object.entries(page).forEach(([key, rawValue]) => {
        if (rawValue) {
          const value = rawValue.toString()
          const pageParam = urlSearchParamKey('page', key)
          urlSearchParams.set(pageParam, value)
          apiSearchParams.set(pageParam, value)
        }
      })
    }

    if (storageEnabled) {
      setSavedFilters(apiSearchParams.toString())
    }

    setApiSearchParams(apiSearchParams.toString())
    history.replace({ search: urlSearchParams.toString() })
  }, [filters, history, page, storageEnabled, setSavedFilters])

  useEffect(() => {
    if (!filters && !page) {
      getSearchHistory()
    } else {
      setSearchHistory()
    }
  }, [filters, page, getSearchHistory, setSearchHistory])

  const filterHandler = (newFilterValues: FilterValues<F>) => {
    // Reset pagination when filtering
    setPage({ ...omit(page, ['before', 'after']) })
    setFilters({ ...newFilterValues })
  }

  const paginateHandler = (pageinateValue: PaginateValue) => {
    if (pageinateValue) {
      setPage({
        ...omit(page, [pageinateValue.before ? 'after' : 'before']),
        ...pageinateValue,
      })
    }
  }

  const sortHandler = (sortValue: SortValue<P>) => {
    if (sortValue) {
      // Reset pagination when sorting
      setPage({
        ...sortValue,
        ...(page?.size ? { size: page.size } : {}),
      })
    }
  }

  function getSortValues(): SortValue<P> {
    const values = page || initialPageVaues
    return omit(values, ['size', 'before', 'after'])
  }

  return {
    apiSearchParams,
    filters,
    page,
    sort: getSortValues(),
    sortHandler,
    paginateHandler,
    filterHandler,
  }
}

export default useSearchParamManager

type ParsedValues<T extends 'filter' | 'page', Model> = T extends 'filter'
  ? FilterValues<Model>
  : PageValues<Model>

function parseUrlValues<URLRootParam extends 'filter' | 'page', Model>(
  urlParams: URLSearchParams,
  rootParam: URLRootParam,
  initialValues: ParsedValues<URLRootParam, Model>
): ParsedValues<URLRootParam, Model> {
  return Object.entries(initialValues).reduce((values, [key, initialValue]) => {
    return {
      ...values,
      [key]: getUrlParamValue(urlParams, rootParam, key, initialValue),
    }
  }, {}) as ParsedValues<URLRootParam, Model>
}

const getUrlParamValue = (
  urlParams: URLSearchParams,
  root: 'filter' | 'page',
  key: string,
  initalValue: FilterValue
) => {
  let value
  const urlParamsValue = urlParams.get(urlSearchParamKey(root, key))

  if (urlParamsValue === 'true' || urlParamsValue === 'false') {
    value = strToBool(urlParamsValue)
  }
  if (Array.isArray(initalValue)) value = urlParamsValue?.split(',')

  return value || urlParamsValue || initalValue
}

function urlSearchParamKey(root: string, ...keys: string[]) {
  return `${root}${keys.map((key) => `[${key}]`)}`
}

function isRangeFilter(filter: FilterValue): filter is RangeFilter {
  return (
    typeof filter === 'object' &&
    filter !== null &&
    'gte' in filter &&
    'lte' in filter &&
    'option' in filter &&
    typeof filter.gte === 'string' &&
    typeof filter.lte === 'string' &&
    typeof filter.option === 'string'
  )
}
