import { RouteComponentProps } from 'react-router-dom'
import { Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import NavigationTabs from 'components/navigation-tabs'

import FactFind from './fact-find'
import Summary from './summary'
import WillUpdates from './will-updates'
import { SentryRoute } from 'instrument'

const StyledNavigationTabs = styled(NavigationTabs)`
  margin-top: -${GTR.M};
`

const Will = ({ match }: RouteComponentProps): React.ReactElement => (
  <>
    <StyledNavigationTabs
      links={[
        { label: 'Summary', url: `${match.url}/summary` },
        { label: 'Fact find', url: `${match.url}/fact-find` },
        { label: 'Will updates', url: `${match.url}/will-updates` },
      ]}
      isSecondary
    />
    <Wrapper separator>
      <SentryRoute path={`${match.path}/summary`} component={Summary} />
      <SentryRoute path={`${match.path}/fact-find`} component={FactFind} />
      <SentryRoute
        path={`${match.path}/will-updates`}
        component={WillUpdates}
      />
    </Wrapper>
  </>
)

export default Will
