import { useEffect } from 'react'
import { Form, useFormikContext } from 'formik'
import styled from 'styled-components'
import { Checkbox, P, Wrapper, Button } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import FilterPanelWrapper from 'components/list/filter-panel-wrapper'
import {
  AI_BOT_STATUS,
  PARTNER_CAUSE_AREA_OPTIONS,
  PARTNER_TYPES_OPTIONS,
  PARTNER_PRODUCTS_OPTIONS,
} from 'lib/models/partner'
import { ArrayFilter, FilterValues } from 'hooks/useSearchParamManager'
import { pluralise } from 'utils/helpers'
import { IndexableKey } from 'utils/types/indexable-key'

const FilterHeaders = styled(Wrapper)`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.S};
  padding-bottom: ${GTR.XS};
  position: relative;
`

const StyledFilter = styled(Wrapper)`
  margin-bottom: ${GTR.L};
`

const StyledCheckbox = styled(Checkbox)`
  margin: ${GTR.S} 0;
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${GTR.M};
`

const StyledUnderlineButton = styled(Button.Underline)`
  padding: 0;
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.S};
`

interface FilterCheckboxProps<T> {
  value: string
  label: string
  filterKey: IndexableKey<T>
}

const FilterCheckbox = <T,>({
  value,
  label,
  filterKey,
}: FilterCheckboxProps<T>) => {
  const { setFieldValue, values } = useFormikContext<FilterValues<T>>()

  const currentValue = values[filterKey] as ArrayFilter

  const handleUpdatingProductsList = () => {
    if (currentValue.includes(value)) {
      setFieldValue(
        filterKey,
        currentValue.filter((product) => product !== value)
      )
    } else {
      setFieldValue(filterKey, [...currentValue, value])
    }
  }

  return (
    <StyledCheckbox
      label={label}
      size="S"
      onChange={handleUpdatingProductsList}
      checked={currentValue.includes(value)}
    />
  )
}

interface FilterPanelProps {
  resultCount: number
}

const FilterPanel = <T,>({ resultCount }: FilterPanelProps) => {
  const { handleSubmit, values, setFieldValue } =
    useFormikContext<FilterValues<T>>()

  useEffect(() => {
    handleSubmit()
  }, [handleSubmit, values])

  const resetFilters = () => {
    return ['type', 'causeArea', 'products', 'aiBot'].forEach((filterKey) =>
      setFieldValue(filterKey, [])
    )
  }

  const appliedFiltersCount = Object.values(values).reduce(
    (count: number, value) =>
      (count += Array.isArray(value) ? value.length : 0),
    0
  )

  return (
    <FilterPanelWrapper>
      <Form>
        <FilterHeaders>
          <P size="M" margin={0} strong>
            Filters
          </P>
        </FilterHeaders>
        <Wrapper margin={[0, 0, GTR.M]}>
          <StyledWrapper>
            {appliedFiltersCount ? (
              <>
                <P size="S" margin={0} color={COLOR.BLACK}>
                  {appliedFiltersCount}{' '}
                  {pluralise(appliedFiltersCount, 'filter')} applied
                </P>

                <StyledUnderlineButton size="S" onClick={resetFilters}>
                  Clear Filters
                </StyledUnderlineButton>
              </>
            ) : null}
          </StyledWrapper>
          <P size="S" color={COLOR.GREY.MEDIUM}>
            {resultCount} results
          </P>
        </Wrapper>

        <StyledFilter>
          <P strong margin={[0, 0, 'XS']} size="S">
            Type
          </P>
          {PARTNER_TYPES_OPTIONS.map((type) => (
            <FilterCheckbox
              key={`${type.value}-filter`}
              label={type.label as string}
              value={type.value as string}
              filterKey="type"
            />
          ))}
        </StyledFilter>
        <StyledFilter>
          <P strong margin={[0, 0, 'XS']} size="S">
            Product
          </P>
          {PARTNER_PRODUCTS_OPTIONS.map((product) => (
            <FilterCheckbox
              key={`${product.value}-filter`}
              label={product.label as string}
              value={product.value as string}
              filterKey="products"
            />
          ))}
        </StyledFilter>
        <StyledFilter>
          <P strong margin={[0, 0, 'XS']} size="S">
            AI marketing bot
          </P>
          {AI_BOT_STATUS.map((product) => (
            <FilterCheckbox
              key={`${product.value}-filter`}
              label={product.label as string}
              value={product.value as string}
              filterKey="aiBot"
            />
          ))}
        </StyledFilter>
        <StyledFilter>
          <P strong margin={[0, 0, 'XS']} size="S">
            Cause Area
          </P>
          {PARTNER_CAUSE_AREA_OPTIONS.map((causeArea) => (
            <FilterCheckbox
              key={`${causeArea.value}-filter`}
              label={causeArea.label as string}
              value={causeArea.value as string}
              filterKey="causeArea"
            />
          ))}
        </StyledFilter>
      </Form>
    </FilterPanelWrapper>
  )
}

export default FilterPanel
