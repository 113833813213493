import { useState, Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import {
  ArrowRightIcon,
  ArrowUpIcon,
  Grid,
  P,
  PencilIcon,
  PhoneMissedSolidIcon,
  PhoneSolidIcon,
  Wrapper,
} from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import moment from 'moment'
import { Formik } from 'formik'
import { getUserIdFromToken } from 'lib/authentication/token'
import styled from 'styled-components'

import {
  getFullNameWithPreferredName,
  dateIsToday,
  formatToHuman,
  truncateText,
  formatProductName,
  getTimeDescriptionFromTimestamp,
} from 'utils/helpers'

import { LEAD_EVENT_TYPES } from 'utils/enums'
import { useFetchArrayResource, RESOURCES } from 'api'

import StyledGridRow from 'components/list/grid-row'
import StyledLink from 'components/list/link'
import NoResults from 'components/list/no-results'
import ErrorResults from 'components/list/error-results'
import StyledLoadingRow from 'components/list/loading-row'
import { SortableColumn } from 'components/list/sortable-column'
import DateBadge from 'components/date-badge'
import PageButtons from 'components/list/page-buttons'
import Tooltip from 'components/tooltip'

import FilterPanel, { Filters } from './filter-panel'
import ConversionScoreIcon from 'components/conversion-score-icon'
import OwnerAssignment from './owner-assignment'
import { LeadEventAttributes } from 'lib/models/lead-event'
import { Lead, LeadAttributes } from 'lib/models/lead'
import useLocalStorage from 'hooks/useLocalStorage'
import useSearchParamManager, {
  FilterValues,
  PageValues,
  SortValue,
} from 'hooks/useSearchParamManager'

const LastActivityIcon = ({ type }: { type: string }) => {
  switch (type) {
    case 'added':
      return <ArrowUpIcon size="S" />
    case 'call_made_connected':
      return <PhoneSolidIcon size="S" />
    case 'call_made_no_answer':
      return <PhoneMissedSolidIcon size="S" />
    case 'comment_added':
      return <PencilIcon size="S" />
    default:
      return null
  }
}

const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
  display: inline-flex;
`
const StyledActivityWrapper = styled(Wrapper)`
  display: flex;
`

const StyledIconWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  color: ${COLOR.GREY.MEDIUM};
`

const LastActivityTime = styled.span`
  margin-left: ${GTR.XS};
`

const StyledTooltip = styled(Tooltip)`
  padding: ${GTR.XXS};
  background-color: ${COLOR.GREY.DARK};
`

const StyledTooltipP = styled(P)`
  margin: 0;
  color: ${COLOR.WHITE};
  font-size: ${FONT.SIZE.S};
`

const StyledSpan = styled.span`
  font-weight: ${FONT.WEIGHT.BOLD};
`

const StyledP = styled(P)`
  text-align: right;
`

const StyledLeadsListHeader = styled(Wrapper)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledCallCountSummary = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  gap: ${GTR.M};

  ${screenMin.s`
    justify-content: flex-end;
  `}
`

const GRID_TEMPLATE_COLUMNS = '1.1fr 1fr 0.75fr 0.75fr 1fr 0.5fr 1fr 30px'

const SORT_CONFIG: Record<string, SortValue<LeadAttributes>> = {
  'Call scheduled': {
    sortColumn: 'scheduledNextCallAt',
    reverseNulls: true,
  },
  'Last activity': {
    sortColumn: 'lastLeadEventHappenedAt',
    reverseNulls: false,
  },
  Score: {
    sortColumn: 'conversionScore',
    reverseNulls: false,
  },
  Stage: {
    sortColumn: 'stageNumber',
    reverseNulls: false,
  },
}

const formatLastActivityTime = (date: string) => {
  if (dateIsToday({ date })) return moment(date).format('HH:mm')
  const timeAgo = moment(date).from(moment().startOf('day'))
  return timeAgo === 'a day ago' ? '1 day ago' : timeAgo
}

const getLastLeadEventIconType = (lastLeadEvent: LeadEventAttributes) => {
  return lastLeadEvent.type === LEAD_EVENT_TYPES.CALL_MADE
    ? `${lastLeadEvent.type}_${lastLeadEvent.metadata.disposition}`
    : lastLeadEvent.type
}

const LeadGrid = ({
  leads = [],
  sort,
  setSort,
  isLoading,
  isError,
  loggedInAdminUserId,
  setEnabled,
}: {
  leads: Lead[]
  sort: SortValue<LeadAttributes>
  setSort: (sort: SortValue<LeadAttributes>) => void
  isLoading: boolean
  isError: boolean
  loggedInAdminUserId: number
  setEnabled: Dispatch<SetStateAction<boolean>>
}) => {
  if (isError) {
    return <ErrorResults type="leads" />
  }

  return (
    <>
      <StyledGridRow header gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
        <SortableColumn
          columnTitle="Call scheduled"
          columnSortValues={SORT_CONFIG['Call scheduled']}
          sortedValues={sort}
          setSort={setSort}
        />
        <StyledGridColumn>Customer</StyledGridColumn>
        <StyledGridColumn>Product</StyledGridColumn>
        <SortableColumn
          columnTitle="Stage"
          columnSortValues={SORT_CONFIG['Stage']}
          sortedValues={sort}
          setSort={setSort}
        />
        <SortableColumn
          columnTitle="Last activity"
          columnSortValues={SORT_CONFIG['Last activity']}
          sortedValues={sort}
          setSort={setSort}
        />
        <SortableColumn
          columnTitle="Score"
          columnSortValues={SORT_CONFIG['Score']}
          sortedValues={sort}
          setSort={setSort}
        />
        <StyledGridColumn>Owner</StyledGridColumn>
        <StyledGridColumn />
      </StyledGridRow>

      {isLoading && <StyledLoadingRow>Loading...</StyledLoadingRow>}
      {!isLoading && leads.length === 0 && (
        <StyledLoadingRow>
          <NoResults type="leads" />
        </StyledLoadingRow>
      )}

      {leads.map((row) => (
        <LeadRow
          key={row.id}
          row={row}
          loggedInAdminUserId={loggedInAdminUserId}
          setEnabled={setEnabled}
        />
      ))}
    </>
  )
}

const LeadRow = ({
  row,
  loggedInAdminUserId,
  setEnabled,
}: {
  row: Lead
  loggedInAdminUserId: number
  setEnabled: Dispatch<SetStateAction<boolean>>
}) => {
  let lastLeadEventIconType

  const {
    lastLeadEvent,
    conversionScore,
    stageName,
    scheduledNextCallAtTimeSet,
    scheduledNextCallAt,
  } = row.attributes
  const contactName = getFullNameWithPreferredName(row.attributes.contact)
  const productName = formatProductName(row.attributes.product)

  if (lastLeadEvent) {
    lastLeadEventIconType = getLastLeadEventIconType(lastLeadEvent)
  }

  return (
    <StyledLink to={`/leads/${row.id}/history`}>
      <StyledGridRow gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
        <StyledGridColumn>
          <StyledTooltip
            enabled={scheduledNextCallAtTimeSet}
            underlined={false}
            content={<StyledTooltipP>Time selected</StyledTooltipP>}
          >
            <DateBadge
              date={getTimeDescriptionFromTimestamp(
                scheduledNextCallAt || undefined,
                scheduledNextCallAtTimeSet
              )}
            />
          </StyledTooltip>
        </StyledGridColumn>
        <StyledGridColumn>{contactName}</StyledGridColumn>
        <StyledGridColumn>{productName}</StyledGridColumn>
        <StyledGridColumn>{stageName}</StyledGridColumn>
        <StyledGridColumn>
          {lastLeadEvent && (
            <StyledTooltip
              maxWidth={300}
              underlined={false}
              content={
                <>
                  <StyledTooltipP strong>
                    {formatToHuman(lastLeadEvent.type)} by{' '}
                    {lastLeadEvent?.adminUser?.name}
                  </StyledTooltipP>
                  {lastLeadEvent.metadata.notes && (
                    <StyledTooltipP>
                      {truncateText(lastLeadEvent.metadata.notes as string, 75)}
                    </StyledTooltipP>
                  )}
                </>
              }
            >
              <StyledActivityWrapper>
                <StyledIconWrapper>
                  {lastLeadEventIconType && (
                    <LastActivityIcon type={lastLeadEventIconType} />
                  )}
                </StyledIconWrapper>
                <LastActivityTime>
                  {formatLastActivityTime(lastLeadEvent.happenedAt)}
                </LastActivityTime>
              </StyledActivityWrapper>
            </StyledTooltip>
          )}
        </StyledGridColumn>
        <StyledGridColumn>
          <ConversionScoreIcon conversionScore={conversionScore} />
        </StyledGridColumn>
        <StyledGridColumn>
          <OwnerAssignment
            lead={row}
            loggedInAdminUserId={loggedInAdminUserId}
            setEnabled={setEnabled}
          />
        </StyledGridColumn>
        <StyledGridColumn>
          <ArrowRightIcon size="S" />
        </StyledGridColumn>
      </StyledGridRow>
    </StyledLink>
  )
}

export const INITIAL_FILTER_VALUES: FilterValues<Filters> = {
  adminUserIds: [],
  partnerId: '',
  product: [],
  sourceIdentifier: '',
  origin: '',
  status: '',
  unsuitableReason: '',
  blockedReason: '',
  callToday: false,
  missedLastCall: false,
  lastChance: false,
  scheduledNextCallAtTimeSet: false,
  lastActivityAt: {
    lte: '',
    gte: '',
    option: 'any',
  },
}

const INITIAL_PAGE_VALUES: PageValues<LeadAttributes> = {
  ...SORT_CONFIG['Call scheduled'],
  sortOrder: 'asc',
  size: 30,
}

const LeadList = () => {
  const [storageEnabled, setStorageEnabled] = useLocalStorage<boolean>(
    'leadListFiltersEnabled',
    false
  )

  const {
    apiSearchParams,
    filters,
    sort,
    filterHandler,
    paginateHandler,
    sortHandler,
  } = useSearchParamManager(INITIAL_FILTER_VALUES, INITIAL_PAGE_VALUES, {
    storageEnabled,
    storageKey: 'leadListFilters',
  })

  const [enabled, setEnabled] = useState(true)

  const token = useSelector((state: { token: string }) => state.token)
  const loggedInAdminUserId = getUserIdFromToken(token)

  const { data, isLoading, isError, isPreviousData } =
    useFetchArrayResource<Lead>(
      {
        resource: RESOURCES.LEADS,
        params: apiSearchParams,
      },
      {
        enabled,
        keepPreviousData: true,
      }
    )

  if (!filters || !sort) return null

  return (
    <Wrapper noTrim>
      <Grid
        gap="L"
        marginFromM={[0, 0, `-${GTR.XL}`]}
        marginFromL={[0, 0, `-${GTR.XXL}`]}
      >
        <Grid.Item span={3}>
          <Formik initialValues={filters} onSubmit={filterHandler}>
            <FilterPanel
              loggedInAdminUserId={loggedInAdminUserId}
              storageEnabled={storageEnabled}
              toggleStorageEnabled={() => setStorageEnabled(!storageEnabled)}
            />
          </Formik>
        </Grid.Item>
        <Grid.Item span={9} padding={['L', 0, 0]}>
          <StyledLeadsListHeader gap={0}>
            {data?.meta && (
              <P size="L" margin={[0, 0, 'M']} strong>
                {data?.meta.totalCount === 1
                  ? '1 lead'
                  : `${data?.meta.totalCount} leads`}
              </P>
            )}
            {data?.meta?.callCountSummary && (
              <StyledCallCountSummary margin={[0, 0, 'M']}>
                <StyledP size="S" strong>
                  Scheduled calls:
                </StyledP>
                <Tooltip
                  content="Leads with a scheduled call that are now in the past."
                  underlined={false}
                >
                  <StyledP size="S">
                    <StyledSpan>
                      {data?.meta?.callCountSummary.overdueCount}{' '}
                    </StyledSpan>
                    overdue
                  </StyledP>
                </Tooltip>
                <Tooltip
                  content="Leads with a call scheduled for today that are in the future."
                  underlined={false}
                >
                  <StyledP size="S">
                    <StyledSpan>
                      {data?.meta?.callCountSummary.todayCount}{' '}
                    </StyledSpan>
                    today
                  </StyledP>
                </Tooltip>
                <Tooltip
                  content="Leads with a call scheduled for tomorrow."
                  underlined={false}
                >
                  <StyledP size="S">
                    <StyledSpan>
                      {data?.meta?.callCountSummary.tomorrowCount}{' '}
                    </StyledSpan>
                    tomorrow
                  </StyledP>
                </Tooltip>
                <Tooltip
                  content="Leads with a call scheduled after tomorrow."
                  underlined={false}
                >
                  <StyledP size="S">
                    <StyledSpan>
                      {data?.meta.callCountSummary.inFutureCount}{' '}
                    </StyledSpan>
                    in the future
                  </StyledP>
                </Tooltip>
              </StyledCallCountSummary>
            )}
          </StyledLeadsListHeader>
          <LeadGrid
            leads={data?.data || []}
            sort={sort}
            setSort={sortHandler}
            isLoading={isLoading}
            isError={isError}
            loggedInAdminUserId={loggedInAdminUserId}
            setEnabled={setEnabled}
          />
          <PageButtons
            disabled={isPreviousData}
            cursors={data?.meta?.cursors}
            setPage={paginateHandler}
          />
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}

export default LeadList
