import * as Sentry from '@sentry/react'
import { Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { RouterHistory } from '@sentry/react/build/types/reactrouter'

if (process.env.APP_ENV === 'production' || process.env.APP_ENV === 'staging') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: ['ChunkLoadError'],
    environment: process.env.REACT_APP_APP_ENV,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        // I am pretty sure the type in the Sentry library is incorrect
        history: createBrowserHistory() as unknown as RouterHistory,
      }),
    ],
  })
}

/**
 * A higher order component that wraps the Route component from react-router-dom with Sentry error handling.
 * @see https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/v4-5/
 */
export const SentryRoute = Sentry.withSentryRouting(Route)
