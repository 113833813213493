import React from 'react'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { Wrapper, Grid } from '@farewill/ui'
import startCase from 'lodash/startCase'
import StyledGridRow from 'components/list/grid-row'
import styled from 'styled-components'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { useFetchArrayResource, RESOURCES } from 'api'
import { Partner } from 'lib/models/partner'
import ExternalLink from 'components/external-link'
import StyledLoadingRow from 'components/list/loading-row'
import NoResults from 'components/list/no-results'
import { SortableColumn } from 'components/list/sortable-column'
import ProductPills from 'routes/partners/components/product-pills'
import { partnerTypeWording } from '../helpers'
import FilterPanel from './filter-panel'
import useSearchParamManager, { SortValue } from 'hooks/useSearchParamManager'

const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
`

const GRID_TEMPLATE_COLUMNS = '1fr 200px 160px 150px 120px'

const INITIAL_FILTER_VALUES = {
  type: [],
  causeArea: [],
  products: [],
  aiBot: [],
}

const SORT_CONFIG: SortValue<Partner['attributes']> = {
  sortColumn: 'name',
  sortOrder: 'asc',
}

const StyledLink = styled(Link)`
  color: ${COLOR.ACCENT.SECONDARY};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StickyGridItem = styled(Grid.Item)`
  position: sticky;
  top: 0;
  max-height: fit-content;
`

const Row = ({ partner }: { partner: Partner }): React.ReactElement => {
  const type = partnerTypeWording(
    partner.attributes.type,
    partner.attributes?.hasExemptCharitableStatus
  )
  const products = partner.attributes.products.map(({ product }) => product)

  return (
    <StyledGridRow gridTemplateColumns={GRID_TEMPLATE_COLUMNS} cursor="auto">
      <StyledLink to={`/partners/${partner.attributes.id}`}>
        <StyledGridColumn>{partner.attributes.name}</StyledGridColumn>
      </StyledLink>

      <StyledGridColumn>{type}</StyledGridColumn>
      <StyledGridColumn>
        {!!products.length && <ProductPills products={products} wrap />}
      </StyledGridColumn>
      <StyledGridColumn>
        {startCase(partner.attributes.causeArea)}
      </StyledGridColumn>
      <StyledGridColumn>
        {partner.attributes.hubspotUrl && (
          <ExternalLink href={partner.attributes.hubspotUrl}>
            Open Hubspot
          </ExternalLink>
        )}
      </StyledGridColumn>
    </StyledGridRow>
  )
}

const PartnersList = (): React.ReactElement => {
  const { apiSearchParams, filters, filterHandler, sort, sortHandler } =
    useSearchParamManager(INITIAL_FILTER_VALUES, SORT_CONFIG, {
      storageEnabled: false,
      storageKey: '',
    })

  const { data: partnersResponse, isLoading } = useFetchArrayResource<Partner>({
    resource: RESOURCES.PARTNERS,
    params: apiSearchParams,
  })

  const partners = partnersResponse?.data

  return (
    <Wrapper>
      <Grid gap="L">
        <Grid.Item span={3}>
          {filters && (
            <Formik initialValues={filters} onSubmit={filterHandler}>
              <FilterPanel resultCount={partners?.length || 0} />
            </Formik>
          )}
        </Grid.Item>
        <StickyGridItem span={9} padding={['L', 0, 0]}>
          <StyledGridRow header gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
            <SortableColumn
              columnTitle="Name"
              columnSortValues={SORT_CONFIG}
              setSort={sortHandler}
              sortedValues={sort}
            />
            <StyledGridColumn>Type</StyledGridColumn>
            <StyledGridColumn>Products</StyledGridColumn>
            <StyledGridColumn>Cause area</StyledGridColumn>
            <StyledGridColumn>Hubspot</StyledGridColumn>
          </StyledGridRow>
          {isLoading && <StyledLoadingRow>Loading...</StyledLoadingRow>}
          {!isLoading && !partners?.length && <NoResults type="partners" />}
          {!isLoading &&
            partners?.map((partner: Partner) => (
              <Row key={partner.attributes.id} partner={partner} />
            ))}
        </StickyGridItem>
      </Grid>
    </Wrapper>
  )
}

export default PartnersList
