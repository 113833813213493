import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useSelector } from 'react-redux'
import { Wrapper } from '@farewill/ui'
import BaseStylesheet, { FontStylesheet } from '@farewill/ui/base'
import 'react-toastify/dist/ReactToastify.css'
import 'tippy.js/dist/tippy.css'

import store from 'state/create-store'
import PAGES from 'config/routes'
import { VIEWS } from 'routes/case-list/views'
import Login from 'routes/login'
import NotFound from 'routes/not-found'
import { removeToken } from 'state/actions'
import useApi from 'lib/effects/api'
import Alert from 'components/alert'
import Environment from 'components/environment'
import Footer from 'components/footer'
import GlobalErrorMessage from 'components/global-error-message'
import Header from 'components/header'
import Modal from 'components/modal'
import PrivateRoute from 'components/private-route'
import SubNavigation from 'components/sub-navigation'
import ToastContainer from 'components/toast-container'

import './app.css'
import { SentryRoute } from 'instrument'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (error.response?.status === 401) {
        store.dispatch(removeToken())
      }
    },
  }),
})
const ONE_DAY = 24 * 60 * 60 * 1000
const DATETIME_PAGE_LOAD = new Date()

const AppRoutes = () => {
  const token = useSelector((state) => state.token)
  const [, fetchAdminUsers] = useApi()
  const [, fetchCelebrants] = useApi()
  const [, fetchCountries] = useApi()
  const [, fetchConstants] = useApi()

  useQuery(
    'adminUsers',
    async () => {
      const response = await fetchAdminUsers({ url: '/api/admin-users' })
      return response.data
    },
    {
      initialData: [],
      initialDataUpdatedAt: DATETIME_PAGE_LOAD,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
      enabled: !!token,
      retry: (failureCount, error) =>
        error.response?.status !== 401 && failureCount < 2,
    }
  )
  useQuery(
    'celebrants',
    async () => {
      const response = await fetchCelebrants({ url: '/api/celebrants' })
      return response.data
    },
    {
      initialData: [],
      initialDataUpdatedAt: DATETIME_PAGE_LOAD,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
      enabled: !!token,
      retry: (failureCount, error) =>
        error.response?.status !== 401 && failureCount < 2,
    }
  )
  useQuery(
    'countries',
    async () => {
      const response = await fetchCountries({ url: '/api/countries' })
      return response.data
    },
    {
      initialData: [],
      initialDataUpdatedAt: DATETIME_PAGE_LOAD,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
      enabled: !!token,
      retry: (failureCount, error) =>
        error.response?.status !== 401 && failureCount < 2,
    }
  )
  useQuery(
    'constants',
    async () => {
      const response = await fetchConstants({ url: '/api/constants/backstage' })
      return response.data
    },
    {
      initialData: [],
      initialDataUpdatedAt: DATETIME_PAGE_LOAD,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
      enabled: !!token,
      retry: (failureCount, error) =>
        error.response?.status !== 401 && failureCount < 2,
    }
  )

  return (
    <Wrapper container containerPaddingTop={0}>
      <SentryRoute path="/" exact component={Login} />
      <Switch>
        <Redirect from="/probate/cases/:id/progress" to="/probate/cases/:id" />
        <Redirect
          from="/probate/estates/:id"
          to="/probate/estates/:id/summary"
          exact
        />
        <Redirect
          from="/will-cases/:id/simple-telephone-will"
          to="/will-cases/:id"
        />
        <Redirect from="/probate/cases" to="/customers/probate" exact />
        <Redirect from="/customers" to="/customers/probate" exact />
        <Redirect from="/cremations/:id" to="/funerals/:id" />
        <Redirect from="/will-vouchers/:id" to="/will-cases/:id" />
        <Redirect
          from="/customers/:product(will|probate|lpa|funeral)"
          to={`/customers/:product/${VIEWS.MY_CASES}`}
          exact
        />
        <Redirect
          from="/customers/:product(funeral-plan)"
          to={`/customers/:product/${VIEWS.ALL}`}
          exact
        />
        {Object.values(PAGES)
          .reduce((acc, obj) => [...acc, ...Object.values(obj)], [])
          .map((route) => (
            <PrivateRoute
              key={route.PATH}
              path={route.PATH}
              requiredRoles={route.REQUIRE_ROLES}
              component={route.VIEW}
            />
          ))}
        <PrivateRoute component={NotFound} />
      </Switch>
    </Wrapper>
  )
}

const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <FontStylesheet assetFolder="/assets/" />
      <BaseStylesheet />
      <Router>
        <ToastContainer />
        <Wrapper style={{ flex: '1 0 auto' }}>
          <Environment />
          <Header />
          <Alert />
          <Modal />
          <SubNavigation />
          <GlobalErrorMessage />
          <AppRoutes />
        </Wrapper>
        <Footer />
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
)

export default App
