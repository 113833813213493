import { useRef, useState } from 'react'
import styled from 'styled-components'
import { Divider, Grid, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { Formik } from 'formik'
import isArray from 'lodash/isArray'
import merge from 'lodash/merge'
import uniq from 'lodash/uniq'
import includes from 'lodash/includes'
import { RouteComponentProps } from 'react-router-dom'
import { Location } from 'history'
import { useClickAway, useMount } from 'react-use'
import snakeCase from 'lodash/snakeCase'
import kebabCase from 'lodash/kebabCase'

import {
  FuneralPlan,
  FuneralPlanSortableColumn,
  SortOrder,
  Status,
  useFuneralPlansQuery,
} from 'api/generated/graphql'
import { getGraphQLClient } from 'api/graphql'
import ButtonNarrow from 'components/button-narrow'
import ErrorResults from 'components/list/error-results'
import PageButtons from 'components/list/page-buttons'
import NavigationTabs from 'components/navigation-tabs'
import TooltipIcon from 'components/tooltip-icon'
import useAdminUserId from 'hooks/useAdminUserId'
import { CASE_LIST_FILTERS_SCHEMA } from 'lib/formik/schemata'
import useApiHelpers from 'lib/effects/api-helpers'
import {
  FAREWILL_PRODUCTS,
  FarewillProduct,
  FUNERAL_PLAN_FEATURE_OPTIONS,
} from 'utils/enums'

import { Page, SetPage, SetSort, SetSortOrder, Sort } from './types'
import FilterPanel from './filter-panel'
import FilterSummary from './filter-summary'
import Cases from './cases'
import TaskList from './task-list'
import WillDrafting from './will-drafting'
import FuneralPlans from './results/funeral-plans'
import { formatGraphQLmeta, getDateRange } from './helpers'
import CaseLoadTable from './case-load-table'
import { caseOwnerId, currentAssigneeId, currentOwnerId } from './constants'
import { VIEWS, View } from './views'
import { SentryRoute } from 'instrument'

const FILTER_PANEL_WIDTH = 308

const StyledFilterRow = styled(Grid)`
  margin: ${GTR.M} 0;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;

  & > * {
    margin: 0;
  }
`

export const SplitNavigationTabs = styled(NavigationTabs)<{
  $dividerPosition: number
}>`
  li:nth-of-type(${({ $dividerPosition }) => $dividerPosition + 1}) {
    position: relative;
  }
  li:nth-of-type(${({ $dividerPosition }) => $dividerPosition + 1}):before {
    content: ' ';
    display: block;
    padding: 0;
    position: absolute;
    top: 6px;
    left: -16px;
    width: 1px;
    height: 16px;
    background-color: ${COLOR.GREY.LIGHT};
  }
`

const CaseLoadWrapper = styled(Wrapper)`
  margin-bottom: 40px;
`

const StyledWrapper = styled.div<{ $isFilterPanelOpen: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: ${GTR.L};
  width: ${FILTER_PANEL_WIDTH}px;
  overflow: auto;
  z-index: 3;
  border-right: 1px solid ${COLOR.GREY.LIGHT};
  background-color: ${COLOR.BACKGROUND.SMOKE};

  ${({ $isFilterPanelOpen }) =>
    $isFilterPanelOpen ? 'display: block;' : 'display: none;'}
`

const BASE_URLS = {
  [FAREWILL_PRODUCTS.PROBATE]: '/api/probate-cases',
  [FAREWILL_PRODUCTS.LPA]: '/api/lpa-cases',
  [FAREWILL_PRODUCTS.FUNERAL]: '/api/funerals',
  [FAREWILL_PRODUCTS.WILL]: '/api/will-cases',
  [FAREWILL_PRODUCTS.FUNERAL_PLAN]: '/api/admin',
}

const API_PRODUCT_TYPES = {
  [FAREWILL_PRODUCTS.PROBATE]: 'probate_cases',
  [FAREWILL_PRODUCTS.LPA]: 'lpa-cases',
  [FAREWILL_PRODUCTS.FUNERAL]: 'funerals',
  [FAREWILL_PRODUCTS.WILL]: 'will_cases',
  [FAREWILL_PRODUCTS.FUNERAL_PLAN]: 'funeral_plan_cases',
}

const PRODUCT_FILTERS = {
  [FAREWILL_PRODUCTS.PROBATE]: {
    SINGLE: [
      'status',
      currentOwnerId,
      'nextStage',
      'nextCoreTask',
      'isPriority',
      'helpNeeded',
      'hideNewLawReferrals',
    ],
    RANGE: ['nextTaskDueOn'],
    DEFAULT: [],
    MY_CASES: currentOwnerId,
    MY_TASKS: currentAssigneeId,
  },
  [FAREWILL_PRODUCTS.LPA]: {
    SINGLE: [currentOwnerId, 'nextStage'],
    DEFAULT: [],
    MY_CASES: currentOwnerId,
  },
  [FAREWILL_PRODUCTS.FUNERAL]: {
    SINGLE: [
      caseOwnerId,
      'nextStage',
      'serviceType',
      'status',
      'unpaid',
      'isDifficult',
      'hasGreenFormPending',
      'hasPaperworkPending',
      'hasFeedbackPending',
      'hasServiceWithinSevenDays',
      'hasSixDaysWithoutContact',
    ],
    DEFAULT: [],
    MY_CASES: caseOwnerId,
  },
  [FAREWILL_PRODUCTS.WILL]: {
    SINGLE: [
      caseOwnerId,
      'nextStage',
      'isUrgent',
      'status',
      'drafterId',
      'grade',
      'helpNeeded',
      'partnerId',
    ],
    DEFAULT: [],
    MY_CASES: caseOwnerId,
    RANGE: ['nextTaskDueOn'],
  },
  [FAREWILL_PRODUCTS.FUNERAL_PLAN]: {
    SINGLE: [
      'status',
      ...FUNERAL_PLAN_FEATURE_OPTIONS.map((option) => option.value),
    ],
    DEFAULT: [],
    MY_CASES: caseOwnerId,
    RANGE: [],
  },
}

const TASK_FILTERS = {
  SINGLE: [
    currentAssigneeId,
    'helpNeededFrom',
    'title',
    'nextCoreTask',
    'probateCaseStatus',
  ],
  RANGE: ['dueOn'],
  DEFAULT: ['isCompleted', 'isNeeded'],
}

export const FILTER_INITIAL_VALUES = {
  isNeeded: true,
  isCompleted: false,
  isPriority: false,
  isDifficult: false,
  hasGreenFormPending: false,
  hasPaperworkPending: false,
  hasFeedbackPending: false,
  hasServiceWithinSevenDays: false,
  hasSixDaysWithoutContact: false,
  isUrgent: false,
  helpNeeded: false,
  hideNewLawReferrals: false,
  status: [],
  currentOwnerId: [],
  currentAssigneeId: [],
  nextStage: [],
  nextCoreTask: [],
  caseOwnerId: [],
  drafterId: [],
  serviceType: [],
  helpNeededFrom: '',
  title: '',
  probateCaseStatus: ['open', 'referral_made', 'case_booked', 'case_closed'],
  nextTaskDueOn: {
    lte: '',
    gte: '',
    option: 'any',
    presets: {
      overdue: false,
      today: false,
      tomorrow: false,
      nextThreeDays: false,
      nextSevenDays: false,
    },
  },
  dueOn: {
    lte: '',
    gte: '',
    option: 'any',
    presets: {
      overdue: false,
      today: false,
      tomorrow: false,
      nextThreeDays: false,
      nextSevenDays: false,
    },
  },
  grade: [],
  hasPhoneNumber: false,
  includesWishes: false,
  includesMessages: false,
}

type FilterRange = {
  lte: string
  gte: string
  option: string
  presets: {
    overdue: boolean
    today: boolean
    tomorrow: boolean
    nextThreeDays: boolean
    nextSevenDays: boolean
  }
}

type FilterValues = {
  isNeeded: boolean
  isCompleted: boolean
  isPriority: boolean
  isUrgent: boolean
  helpNeeded: boolean
  hideNewLawReferrals: boolean
  status: string[]
  currentOwnerId: string[]
  currentAssigneeId: string[]
  nextStage: string[]
  nextCoreTask: string[]
  helpNeededFrom: string
  title: string
  probateCaseStatus: string[]
  nextTaskDueOn: FilterRange
  dueOn: FilterRange
  grade: string[]
  hasPhoneNumber: boolean
  includesWishes: boolean
  includesMessages: boolean
}
type FilterSingleKey = string
type FilterRangeKey = string

// `PropertyKey` is short for "string | number | symbol"
// since an object key can be any of those types, our key can too
function hasKey<O extends object>(obj: O, key: PropertyKey): key is keyof O {
  return key in obj
}

const getChangedFilterValues = (
  values: FilterValues,
  original: FilterValues = FILTER_INITIAL_VALUES
): Partial<FilterValues> => {
  const changed: { [key: string]: unknown } = {}
  const keys = uniq([...Object.keys(values), ...Object.keys(original)])

  for (const key of keys) {
    if (
      hasKey(values, key) &&
      hasKey(original, key) &&
      original[key] !== values[key] &&
      values[key] != null
    ) {
      changed[key] = values[key]
    }
  }

  return changed as Partial<FilterValues>
}

const allCasesPath = (location: Location) => {
  const pathname = location.pathname.replace(
    /\/[^/]+$/ /* match last part of pathname */,
    `/${VIEWS.ALL}`
  )
  return `${pathname}?${location.search}`
}

const parseSearchString = (
  location: { search: string },
  product: FarewillProduct,
  isTasksView: boolean,
  setSort: SetSort,
  setSortOrder: SetSortOrder,
  setPage: SetPage,
  setFilterValues: React.Dispatch<React.SetStateAction<FilterValues>>
) => {
  const params = new URLSearchParams(location.search)
  const filters = isTasksView ? TASK_FILTERS : PRODUCT_FILTERS[product]
  setSort(params.get('sort') as Sort)
  setSortOrder(params.get('sortOrder') as SortOrder)

  setPage({
    after: params.get('page[after]'),
    before: params.get('page[before]'),
  })

  filters.SINGLE.forEach((filter: FilterSingleKey) => {
    const value = params.get(`filter[${filter}]`)
    if (value) {
      const filterValues = JSON.parse(value)
      setFilterValues((prevState) => {
        return { ...prevState, [filter]: filterValues }
      })
    }
  })

  'RANGE' in filters &&
    filters.RANGE.forEach((filter: FilterRangeKey) => {
      const option = params.get(`${filter}[option]`)
      const lte = params.get(`${filter}[lte]`)
      const gte = params.get(`${filter}[gte]`)
      const presets = params.get(`${filter}[presets]`)

      if (!!gte && !!lte)
        setFilterValues((prevState) => {
          return merge({}, prevState, {
            [filter]: {
              lte,
              gte,
              option,
            },
          })
        })
      if (presets) {
        const activePresets = presets.split(',')
        const dateRange = getDateRange(presets)

        activePresets.forEach((preset) => {
          setFilterValues((prevState) => {
            return merge({}, prevState, {
              [filter]: {
                lte: dateRange.lte,
                gte: dateRange.gte,
                presets: { [preset]: true },
              },
            })
          })
        })
      }
    })
}

const getNavigationLinks = (product: string) => {
  const allCases = {
    label: 'All cases',
    url: `/customers/${product}/${VIEWS.ALL}`,
  }

  if (product === kebabCase(FAREWILL_PRODUCTS.FUNERAL_PLAN)) {
    return [allCases]
  }

  const caseNavigationLinks: { label: string; url: string; exact?: boolean }[] =
    [
      {
        label: 'My cases',
        url: `/customers/${product}/${VIEWS.MY_CASES}`,
        exact: true,
      },
      allCases,
    ]

  if (product === FAREWILL_PRODUCTS.PROBATE) {
    return [
      ...caseNavigationLinks,
      {
        label: 'My tasks',
        url: `/customers/${product}/${VIEWS.MY_TASKS}`,
      },
      {
        label: 'All tasks',
        url: `/customers/${product}/${VIEWS.TASKS}`,
      },
    ]
  }

  if (product === FAREWILL_PRODUCTS.WILL) {
    return [
      ...caseNavigationLinks,
      {
        label: 'Will drafting',
        url: `/customers/will/${VIEWS.WILL_DRAFTING}`,
      },
    ]
  }

  return caseNavigationLinks
}

type RouteMatchParams = {
  product: FarewillProduct
  view: View
}

const CaseList = ({
  match,
  history,
}: RouteComponentProps<RouteMatchParams>): React.ReactElement => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false)
  const ref = useRef(null)

  const onResetFilters = () => {
    setPage({})
    setSort(null)
    handleFilterSubmit(FILTER_INITIAL_VALUES)
  }

  const onApplyFilters = () => {
    setIsFilterPanelOpen(false)
  }

  useClickAway(ref, () => {
    if (isFilterPanelOpen) {
      setIsFilterPanelOpen(false)
    }
  })

  const [page, setPage] = useState<Page>({})
  const [sort, setSort] = useState<Sort>()
  const [sortOrder, setSortOrder] = useState<SortOrder | null | undefined>()
  const [filterValues, setFilterValues] = useState<FilterValues>(
    FILTER_INITIAL_VALUES
  )

  const [lastFetchParams, setLastFetchParams] = useState(new URLSearchParams())

  const loggedInAdminUserId = useAdminUserId()

  const { product: kebabCaseProduct, view } = match.params
  const product = snakeCase(kebabCaseProduct) as FarewillProduct
  const isTasksView = view === VIEWS.TASKS || view === VIEWS.MY_TASKS

  const baseUrl = isTasksView ? '/api/tasks' : BASE_URLS[product]
  const {
    items: data,
    fetchItems,
    isFetching: isLoading,
    fetchErrors: errors,
    fetchMeta,
    updateItem,
  } = useApiHelpers({
    baseUrl,
    type: isTasksView ? VIEWS.TASKS : API_PRODUCT_TYPES[product],
  })

  useMount(() =>
    parseSearchString(
      history.location,
      product,
      isTasksView,
      setSort,
      setSortOrder,
      setPage,
      setFilterValues
    )
  )

  const handleFilterSubmit = (values: FilterValues) => {
    const params = new URLSearchParams()
    const searchParams = new URLSearchParams()
    const filters = isTasksView ? TASK_FILTERS : PRODUCT_FILTERS[product]
    const productFilters = PRODUCT_FILTERS[product]

    const simpleFilters = [...filters.SINGLE, ...filters.DEFAULT]
    simpleFilters.forEach((filter: FilterSingleKey) => {
      if (!hasKey(values, filter)) {
        return
      }

      const isDefault = includes(filters.DEFAULT, filter)
      const value = values[filter]
      const filterHasValue = isArray(value) ? !!value.length : !!value
      if (isDefault || filterHasValue) {
        params.set(`filter[${filter}]`, value.toString())
        searchParams.set(`filter[${filter}]`, JSON.stringify(value))
      }
    })

    const setUserIdParam = (productFilter: string) => {
      if (loggedInAdminUserId) {
        params.set(`filter[${productFilter}]`, String(loggedInAdminUserId))
      }
    }

    if (view === VIEWS.WILL_DRAFTING) {
      params.set(`filter[nextStage]`, 'draft_documents')
    }

    if (view === VIEWS.MY_CASES) {
      setUserIdParam(productFilters.MY_CASES)
    }

    if (view === VIEWS.MY_TASKS && 'MY_TASKS' in productFilters) {
      setUserIdParam(productFilters.MY_TASKS)
    }

    'RANGE' in filters &&
      filters.RANGE.forEach((filter: FilterRangeKey) => {
        if (!hasKey(values, filter)) {
          return
        }

        const { gte, lte, option, presets } = values[filter] as FilterRange
        if (!gte || !lte) return

        params.set(`filter[${filter}][gte]`, gte)
        params.set(`filter[${filter}][lte]`, lte)
        searchParams.set(`${filter}[gte]`, gte)
        searchParams.set(`${filter}[lte]`, lte)

        if (option === 'custom') {
          searchParams.set(`${filter}[option]`, `custom`)
        } else if (option === 'preset') {
          const activePresets = Object.keys(presets).filter((preset) => {
            if (!hasKey(presets, preset)) {
              return false
            }
            return presets[preset]
          })

          searchParams.set(`${filter}[option]`, `preset`)
          searchParams.set(`${filter}[presets]`, activePresets.join())
        }
      })

    Object.keys(page).forEach((key) => {
      if (!hasKey(page, key)) {
        return
      }

      const value = page[key]
      if (typeof value === 'string') {
        params.set(`page[${key}]`, value)
        searchParams.set(`page[${key}]`, value)
      }
    })

    if (sort) {
      params.set('sort', sort)
      searchParams.set(`sort`, sort)
    }

    if (sortOrder) {
      params.set('sortOrder', sortOrder)
      searchParams.set('sortOrder', sortOrder)
    }

    history.push({ search: searchParams.toString() })
    if (isTasksView) params.set('filter[product]', product)

    setFilterValues(values)
    setLastFetchParams(params)
    fetchCaseList(params)
  }

  const client = getGraphQLClient()
  const filteredForFuneralPlanStatus = filterValues.status.filter((status) =>
    (Object.values(Status) as string[]).includes(status)
  )
  const funeralPlansQuery = useFuneralPlansQuery(
    client,
    {
      status: filteredForFuneralPlanStatus as Status[],
      before: page.before,
      after: page.after,
      field: sort as FuneralPlanSortableColumn,
      order: sortOrder,
      hasPhoneNumber: filterValues.hasPhoneNumber,
      includesWishes: filterValues.includesWishes,
      includesMessages: filterValues.includesMessages,
    },
    { enabled: false }
  )

  const meta =
    product === FAREWILL_PRODUCTS.FUNERAL_PLAN
      ? formatGraphQLmeta(funeralPlansQuery.data?.funeralPlans)
      : fetchMeta

  const fetchCaseList = (queryParams = lastFetchParams) => {
    // funeral plan product is special and uses graphql
    if (product === FAREWILL_PRODUCTS.FUNERAL_PLAN) {
      funeralPlansQuery.refetch()
    } else {
      fetchItems({ queryParams: Object.fromEntries(queryParams.entries()) })
    }
  }

  const handleUpdateCase = async (id: number, attributes: unknown) => {
    await updateItem(id, attributes)
    await fetchCaseList()
  }

  if (errors?.length || funeralPlansQuery.isError) {
    return <ErrorResults type="cases" />
  }

  return (
    <>
      {product === FAREWILL_PRODUCTS.FUNERAL && (
        <CaseLoadWrapper padding={[GTR.L, 0, 0]}>
          <CaseLoadTable
            cases={data}
            onSelectUser={(caseOwnerId: number) => {
              handleFilterSubmit(
                merge({}, filterValues, {
                  caseOwnerId: [caseOwnerId],
                })
              )

              history.push(allCasesPath(history.location))
            }}
          />
        </CaseLoadWrapper>
      )}

      <SplitNavigationTabs
        borderColor={COLOR.ACCENT.SECONDARY}
        isSecondary
        $dividerPosition={2}
        links={getNavigationLinks(kebabCaseProduct)}
      />

      <StyledFilterRow>
        <ButtonNarrow.Secondary
          type="button"
          onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
        >
          Filter
        </ButtonNarrow.Secondary>
        <FilterSummary
          filters={getChangedFilterValues(filterValues)}
          onReset={onResetFilters}
          product={product}
        />
      </StyledFilterRow>

      <Divider />

      <Grid gap="L" data-testid={`${product}-case-list`}>
        <StyledWrapper ref={ref} $isFilterPanelOpen={isFilterPanelOpen}>
          <Formik
            onSubmit={handleFilterSubmit}
            validationSchema={CASE_LIST_FILTERS_SCHEMA}
            initialValues={filterValues}
            enableReinitialize
          >
            <FilterPanel
              page={page}
              setPage={setPage}
              sort={sort}
              setSort={setSort}
              view={view}
              product={product}
              onApplyFilters={onApplyFilters}
              onResetFilters={onResetFilters}
            />
          </Formik>
        </StyledWrapper>

        <Grid.Item padding={['L', 0, 0]}>
          {meta && (
            <P size="L" margin={[0, 'XS', 'M']} strong>
              {meta?.page?.total === 1
                ? '1 Result'
                : `${meta?.page?.total} Results`}
              {view === VIEWS.WILL_DRAFTING && (
                <>
                  {' '}
                  <TooltipIcon
                    content="A list of will cases that are in the ‘Draft documents’ stage"
                    maxWidth={290}
                  />
                </>
              )}
            </P>
          )}
          <SentryRoute
            path={[`/customers/funeral-plan/${VIEWS.ALL}`]}
            render={(props) => (
              <FuneralPlans
                {...props}
                funeralPlans={
                  funeralPlansQuery?.data?.funeralPlans?.edges
                    ?.node as FuneralPlan[]
                }
                isLoading={
                  funeralPlansQuery.isLoading || funeralPlansQuery.isIdle
                }
                sort={sort}
                setSort={setSort}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                setPage={setPage}
              />
            )}
            exact
          />
          <SentryRoute
            path={[
              `/customers/:product(probate|funeral|will|lpa)/${VIEWS.ALL}`,
              `/customers/:product(probate|funeral|will|lpa)/${VIEWS.MY_CASES}`,
            ]}
            render={(props) => (
              <Cases
                {...props}
                cases={data}
                isLoading={isLoading}
                sort={sort}
                setSort={setSort}
                setPage={setPage}
                product={product}
                updateCase={handleUpdateCase}
              />
            )}
            exact
          />
          <SentryRoute
            path={[
              `/customers/${product}/${VIEWS.TASKS}`,
              `/customers/${product}/${VIEWS.MY_TASKS}`,
            ]}
            render={(...props) => (
              <TaskList
                {...props}
                tasks={data}
                isLoading={isLoading}
                sort={sort}
                setSort={setSort}
                setPage={setPage}
                product={product}
              />
            )}
          />
          <SentryRoute
            path={`/customers/will/${VIEWS.WILL_DRAFTING}`}
            render={(props) => (
              <WillDrafting
                {...props}
                cases={data}
                isLoading={isLoading}
                sort={sort}
                setSort={setSort}
                setPage={setPage}
              />
            )}
          />
          <PageButtons cursors={meta?.cursors} setPage={setPage} />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default CaseList
