import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import filter from 'lodash/filter'
import { Button, Divider, Grid, H, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { useArrayResource, useFetchResource, RESOURCES } from 'api'
import { Gift, GiftType } from 'lib/models/gift'
import { WillCase } from 'lib/models/will-case'
import GridWithToggle from 'components/grid-with-toggle'

import GiftCard from './gift-card'
import ResiduaryGift from './gift-card/residuary'
import MoneyGift from './gift-card/money'
import { GIFT_TYPE } from './constants'
import ContactPermissions from './contact-permissions'
import { UpdateResourceFn } from './types'

type NewMoneyGift = {
  type: GiftType
  willId: number
  money: Record<string, never>
}

type NewResiduaryGift = {
  type: GiftType
  willId: number
  residuary: {
    inheritanceOrder: '1' | '2'
  }
}

const StyledButton = styled(Button.Secondary)`
  padding-top: ${GTR.XXS};
  padding-bottom: ${GTR.XXS};
`

type Props = {
  updateWill: UpdateResourceFn
}

const Gifts = ({ updateWill }: Props): React.ReactElement => {
  const { id: willCaseId, willId } = useParams<{ id: string; willId: string }>()

  const willCaseQuery = useFetchResource<WillCase>({
    id: Number(willCaseId),
    resource: RESOURCES.WILL_CASES,
  })

  const isCouplesWillCase = willCaseQuery.data?.attributes.wills?.length === 2

  const {
    query: giftsQuery,
    updateMutation: giftsUpdateMutation,
    createMutation: giftsCreateMutation,
    deleteMutation: giftsDeleteMutation,
  } = useArrayResource<Gift>({
    resource: RESOURCES.GIFTS,
    params: `filter[willId]=${willId}`,
  })

  const createGift = async (attributes: NewMoneyGift | NewResiduaryGift) => {
    giftsCreateMutation.mutate({
      attributes,
      resource: RESOURCES.GIFTS,
    })
  }

  if (giftsQuery.isLoading || giftsQuery.isIdle) return <P>Loading...</P>

  const residuaryGifts = filter(giftsQuery.data?.data, [
    'attributes.type',
    GIFT_TYPE.RESIDUARY,
  ])

  const residuaryGiftsPrimary = filter(residuaryGifts, [
    'attributes.residuary.inheritanceOrder',
    '1',
  ])

  const residuaryGiftsCatastrophe = filter(residuaryGifts, [
    'attributes.residuary.inheritanceOrder',
    '2',
  ])

  const moneyGifts = filter(giftsQuery.data?.data, [
    'attributes.type',
    GIFT_TYPE.MONEY,
  ])

  return (
    <GridWithToggle
      disabled={residuaryGifts.length > 0 || moneyGifts.length > 0}
      name="giftsSectionActive"
      onToggle={updateWill}
      scrollId="2."
      title="Gifts to charities and organisations"
      titleSize="M"
    >
      <Grid.Item>
        <Wrapper>
          <P margin={[0, 0, 'L']}>
            Use this section to record any gifts left to charities and
            organisations.
          </P>
          <H size="S" margin={[0, 0, 'M']}>
            2.1 Primary beneficiaries (residuary gifts)
          </H>
          <Wrapper margin={[0, 0, 'S', 0]}>
            {residuaryGiftsPrimary.map((gift, index) => (
              <GiftCard
                key={gift.id}
                gift={gift}
                cardType="Primary beneficiary"
                listLength={residuaryGiftsPrimary.length}
                listPosition={index + 1}
                giftDetails={ResiduaryGift}
                giftsUpdateMutation={giftsUpdateMutation}
                giftsDeleteMutation={giftsDeleteMutation}
                giftType={GIFT_TYPE.RESIDUARY}
                addNewBeneficiaryLabel="Who would they like to name as the primary beneficiary?"
                isCouplesWillCase={isCouplesWillCase}
              />
            ))}
          </Wrapper>

          <StyledButton
            onClick={() =>
              createGift({
                willId: Number(willId),
                type: 'residuary',
                residuary: {
                  inheritanceOrder: '1',
                },
              })
            }
          >
            Add primary beneficiary
          </StyledButton>

          <Divider margin={['L', 0]} />
          <H size="S" margin={[0, 0, 'M']}>
            2.2 Catastrophe (wipeout) beneficiaries
          </H>
          <Wrapper margin={[0, 0, 'S', 0]}>
            {residuaryGiftsCatastrophe.map((gift, index) => (
              <GiftCard
                key={gift.id}
                gift={gift}
                cardType="Catastrophe (wipeout) beneficiary"
                listLength={residuaryGiftsCatastrophe.length}
                listPosition={index + 1}
                giftDetails={ResiduaryGift}
                giftsUpdateMutation={giftsUpdateMutation}
                giftsDeleteMutation={giftsDeleteMutation}
                giftType={GIFT_TYPE.RESIDUARY}
                addNewBeneficiaryLabel="Who would they like to name as the catastrophe (wipeout) beneficiary?"
                isCouplesWillCase={isCouplesWillCase}
              />
            ))}
          </Wrapper>

          <StyledButton
            onClick={() =>
              createGift({
                willId: Number(willId),
                type: 'residuary',
                residuary: {
                  inheritanceOrder: '2',
                },
              })
            }
          >
            Add catastrophe beneficiary
          </StyledButton>

          <Divider margin={['L', 0]} />
          <H size="S" margin={[0, 0, 'M']}>
            2.3 Gifts of money (pecuniary gifts)
          </H>
          <Wrapper margin={[0, 0, 'S', 0]}>
            {moneyGifts.map((gift, index) => (
              <GiftCard
                key={gift.id}
                gift={gift}
                cardType="Gift of money"
                listLength={moneyGifts.length}
                listPosition={index + 1}
                giftDetails={MoneyGift}
                giftsUpdateMutation={giftsUpdateMutation}
                giftsDeleteMutation={giftsDeleteMutation}
                giftType={GIFT_TYPE.MONEY}
                addNewBeneficiaryLabel="Who would they like to leave this gift to?"
                isCouplesWillCase={isCouplesWillCase}
              />
            ))}
          </Wrapper>

          <StyledButton
            onClick={() =>
              createGift({
                willId: Number(willId),
                type: 'money',
                money: {},
              })
            }
          >
            Add gift of money
          </StyledButton>
        </Wrapper>
        <Divider margin={['L', 0]} />
        <ContactPermissions updateWill={updateWill} />
      </Grid.Item>
    </GridWithToggle>
  )
}

export default Gifts
