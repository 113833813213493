import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Switch, useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Grid, Wrapper, Button, P } from '@farewill/ui'

import { FuneralPlan } from 'lib/models/funeral-plan'
import { TasksProvider } from 'components/tasks-panel/context'
import Header from 'components/product-header'
import NavigationTabs from 'components/navigation-tabs'
import Activity from 'components/activity'
import NotFoundMessage from 'components/not-found-message'
import { FAREWILL_PRODUCTS } from 'utils/enums'
import {
  fetchFuneralPlan,
  fetchRedeemFuneralPlan,
  showModal,
  unsetProduct,
} from 'state/actions'

import CaseDetails from './case-details'
import Payment from './payment'
import RedeemPlanModal from './redeem-plan-modal'
import Documents from './documents'
import ConvertedMessage from './converted-message'
import { GTR } from '@farewill/ui/tokens'
import { SentryRoute } from 'instrument'

const getNavLinks = (funeralPlan: FuneralPlan) => {
  const navLinks = [
    { label: 'Plan details', url: `/funeral-plans/${funeralPlan.id}` },
    {
      label: 'Documents',
      url: `/funeral-plans/${funeralPlan.id}/documents`,
    },
    {
      label: 'Payment',
      url: `/funeral-plans/${funeralPlan.id}/payment`,
    },
    { label: 'Activity', url: `/funeral-plans/${funeralPlan.id}/activity` },
  ]

  return navLinks
}

const StyledWrapper = styled(Wrapper)`
  position: relative;
`

const FuneralPlanCase = (): React.ReactElement => {
  const { id: funeralPlanId } = useParams<{ id: string }>()
  const { funeralPlan } = useSelector(
    (state: { funeralPlanCase: FuneralPlan }) => ({
      funeralPlan: state.funeralPlanCase,
    }),
    shallowEqual
  )
  const [isRedeemed, setIsRedeemed] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFuneralPlan(funeralPlanId))

    return () => {
      dispatch(unsetProduct('funeral-plan'))
    }
  }, [dispatch, funeralPlanId])

  useEffect(() => {
    dispatch(fetchFuneralPlan(funeralPlanId))
  }, [dispatch, funeralPlanId])

  const handlePlanRedeem = () => {
    dispatch(
      showModal({
        component: RedeemPlanModal,
        headingText: 'Redeem this funeral plan?',
        config: {
          data: funeralPlan,
          redeemPlan: async () => {
            await dispatch(fetchRedeemFuneralPlan(funeralPlanId))
            await dispatch(fetchFuneralPlan(funeralPlanId))
            setIsRedeemed(true)
          },
        },
      })
    )
  }

  if (!funeralPlan.id) return <P size="L">Loading...</P>

  return (
    <StyledWrapper
      data-sticky-content="funeral-plan-case"
      padding={[GTR.XL, 0, 0]}
    >
      <Header
        attributes={funeralPlan.attributes}
        productType={FAREWILL_PRODUCTS.FUNERAL_PLAN}
      />
      {funeralPlan.attributes.status === 'closed' &&
        funeralPlan.attributes?.funeralLead?.id && (
          <Wrapper margin={['M', 0, 0]}>
            <ConvertedMessage funeralPlan={funeralPlan} />
          </Wrapper>
        )}
      {funeralPlan?.attributes.status === 'active' && (
        <Wrapper margin={['M', 0, 0]}>
          <Button.Primary
            disabled={isRedeemed}
            onClick={() => handlePlanRedeem()}
          >
            Redeem Funeral Plan
          </Button.Primary>
        </Wrapper>
      )}
      <Wrapper data-cy="navigation-bar" separator>
        <NavigationTabs links={getNavLinks(funeralPlan)} />
      </Wrapper>
      <Grid separator>
        <Grid.Item span={8}>
          <Switch>
            <SentryRoute
              path="/funeral-plans/:id"
              exact
              component={CaseDetails}
            />
            <SentryRoute
              path="/funeral-plans/:id/documents"
              component={Documents}
            />
            <SentryRoute
              path="/funeral-plans/:id/payment"
              render={() => <Payment />}
            />
            <SentryRoute
              path="/funeral-plans/:id/activity"
              render={({ history, location, match }) => (
                <Activity
                  history={history}
                  location={location}
                  match={match}
                  leadId={funeralPlan?.attributes.leadId}
                  product={FAREWILL_PRODUCTS.FUNERAL_PLAN}
                />
              )}
            />
            <SentryRoute render={() => <NotFoundMessage />} />
          </Switch>
        </Grid.Item>
      </Grid>
    </StyledWrapper>
  )
}

const FuneralPlanCaseWithProvider = (): React.ReactElement => (
  <TasksProvider product={FAREWILL_PRODUCTS.FUNERAL_PLAN}>
    <FuneralPlanCase />
  </TasksProvider>
)

export default FuneralPlanCaseWithProvider
